import styled from 'styled-components'
import Img from 'gatsby-image'
import elem from 'src/img/background_pattern.svg'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Background = styled.div`
  position: absolute;
  top: -24px;
  ${$props => $props.position}: -24px;
  height: 90%;
  width: 140px;
  background-image: url(${elem});
`

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
`
