import React from 'react'
import PropTypes from 'prop-types'
import MyAniLink from 'src/components/MyAniLink'
import { Button } from '@material-ui/core/'

const LinkButton = ({ to, children, size }) => (
  <MyAniLink to={to}>
    <Button
      color="primary"
      variant="contained"
      disableElevation
      component="span"
      size={size}
    >
      {children}
    </Button>
  </MyAniLink>
)

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
}

LinkButton.defaultProps = {
  size: 'large',
}

export default LinkButton
