import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import { Grid, Box } from '@material-ui/core/'
import Heading from 'src/components/Heading'
import Wysiwyg from 'src/components/Wysiwyg'
import LinkButton from 'src/components/LinkButton'
import { getRelativeUrl } from 'src/utils/urlUtils'

const ContactCta = ({
  heading,
  textWysiwyg,
  link,
  background,
  beforeFooter,
}) => (
  <Section
    hasPaddingLarge
    paddingValuesLarge={
      beforeFooter ? { xs: '48px 0  96px 0', lg: '100px 0 148px 0' } : undefined
    }
    background={background}
  >
    <Grid container>
      <Grid item md={2} />
      <Grid item xs={12} md={8}>
        <Box mb={6}>
          <Heading align="center">{heading}</Heading>
        </Box>
        <Wysiwyg align="center">{textWysiwyg}</Wysiwyg>
        <Box display="flex" justifyContent="center" mt={6}>
          <LinkButton to={getRelativeUrl(link.url)}>{link.title}</LinkButton>
        </Box>
      </Grid>
    </Grid>
  </Section>
)

ContactCta.propTypes = {
  heading: PropTypes.string.isRequired,
  textWysiwyg: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  beforeFooter: PropTypes.bool,
}

ContactCta.defaultProps = {
  background: undefined,
  beforeFooter: false,
}

export default ContactCta
