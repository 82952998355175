import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Background, Image } from './style'

const ImageWithBackground = ({ position, alt, imgFluid }) => (
  <Wrapper>
    <Background position={position} />
    <Image fluid={imgFluid} position={position} alt={alt} />
  </Wrapper>
)

ImageWithBackground.defaultProps = {
  position: 'left',
  alt: '',
}

ImageWithBackground.propTypes = {
  position: PropTypes.string,
  alt: PropTypes.string,
  imgFluid: PropTypes.object.isRequired, // eslint-disable-line
}

export default ImageWithBackground
