import React from 'react'
import PropTypes from 'prop-types'
import { getThemeVar } from 'src/styles/mixins'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
import Section from 'src/components/Section'
import SectionPattern from 'src/components/SectionPattern'
import ImageWithBackground from 'src/components/ImageWithBackground'
import { Grid, Box } from '@material-ui/core/'
import ContactCta from 'src/sections/contact/ContactCta'

export const MissionVisionTemplate = ({ acf }) => {
  const mission = acf.mvMission
  const vision = acf.mvVision
  const cta = acf.mvCta

  return (
    <>
      <Section background={getThemeVar('color.lightblue')} hasPadding>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={5}
        >
          <Grid item xs={12} md={6}>
            <Heading2Line
              preHeading={mission.preHeading}
              heading={mission.heading}
            />
            <Wysiwyg>{mission.textWysiwyg}</Wysiwyg>
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageWithBackground
              imgFluid={mission.mImage.localFile.childImageSharp.fluid}
              alt={mission.mImage.altText}
              position="right"
            />
          </Grid>
        </Grid>
        <SectionPattern positionX="right" />
      </Section>
      <Section hasPadding>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={5}
        >
          <Box clone order={{ xs: 2, md: 1 }}>
            <Grid item xs={12} md={6}>
              <ImageWithBackground
                imgFluid={vision.vImage.localFile.childImageSharp.fluid}
                alt={vision.vImage.altText}
                position="left"
              />
            </Grid>
          </Box>
          <Box clone order={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={6}>
              <Heading2Line
                preHeading={vision.preHeading}
                heading={vision.heading}
              />
              <Wysiwyg>{vision.textWysiwyg}</Wysiwyg>
            </Grid>
          </Box>
        </Grid>
        <SectionPattern positionX="left" />
      </Section>
      <ContactCta
        heading={cta.heading}
        textWysiwyg={cta.textWysiwyg}
        link={cta.link}
        background={getThemeVar('color.lightblue')}
        beforeFooter
      />
    </>
  )
}

MissionVisionTemplate.propTypes = {
  acf: PropTypes.shape({
    mvMission: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      mImage: PropTypes.object, // eslint-disable-line
    }),

    mvVision: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      vImage: PropTypes.object, // eslint-disable-line
    }),

    mvCta: PropTypes.shape({
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default MissionVisionTemplate
